<template>
  <base-section v-if="!m_isEmptyQuote" id="k-d-a-project-detail-quote" space="180" top-inner-shadow>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12" md="12">
          <component
            :is="m_objContent.strComponent"
            :class="`kda-ts-${g_bLowerBr ? '24' : '36'}pt ln-160 wt-extrabold mx-auto px-${
              g_bLowerBr ? 4 : 0
            }`"
            v-html="m_objContent.htmlText"
          />
          <component
            :is="m_objName.strComponent"
            :class="`kda-ts-${
              g_bLowerBr ? '16' : '24'
            }pt ln-160 wt-semibold nunito mx-auto mt-8 px-${g_bLowerBr ? 4 : 0}`"
            >{{ m_objName.strName }}</component
          >
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAProjectDetailQuote',

  mixins: [BusinessDetails, BaseBreakpoint],

  computed: {
    m_currentRoute() {
      return this.$route.params.strProject;
    },
    m_isEmptyQuote() {
      return (
        this.$vuetify.lang.t(`$vuetify.kda.project.detail.item-${this.m_currentRoute}.quote`) === ''
      );
    },
    m_objContent() {
      return {
        htmlText: `${this.$vuetify.lang.t(
          `$vuetify.kda.project.detail.item-${this.m_currentRoute}.quote`
        )}`,
        strComponent: 'p'
      };
    },
    m_objName() {
      return {
        strName: this.$vuetify.lang.t(
          `$vuetify.kda.project.detail.item-${this.m_currentRoute}.quote-by`
        ),
        strComponent: 'p'
      };
    }
  }
};
</script>
